<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<CartelSetting
			:user="user"

			class="flex-side mr-30"
		></CartelSetting>

		<div class="flex-1 bg-white pa-20 radius-20">

			<div class="under-line-dick pb-10 flex-row">
				<h3 class="flex-1">카르텔 폐쇄</h3>
			</div>

			<div class="mt-30 text-center">

				<div class="bg-icon01 mt-50">
					<h4>카르텔 폐쇄 전 꼭 확인해주세요</h4>
				</div>

				<p class="mt-30">
					해당 카르텔 폐쇄를 위해서는 멤버가 없어야 합니다. <br> 폐쇄 신청을 할 경우 24시간 뒤 폐쇄가 됩니다.
				</p>

				<div class="mt-30">
					<input
						v-model="is_out"
						type="checkbox" id="resign"
						:disabled="item_cartel.user_count > 0"
					>
					<label for="resign"> 카르텔 폐쇄에 동의합니다.</label>
				</div>
				<div class="mt-30">
					<button
						@click="onPin"
						class="btn-inline btn-primary pa-20 radius-20"
						:disabled="!is_out"
					>폐쇄하기</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import CartelSetting from "@/view/Cartel/CartelSetting";
export default {
	name: 'mafia0831'
	, components: {CartelSetting}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '카르텔 폐쇄'
				, title: '카르텔 폐쇄'
				, type: 'cartel_sub'
				, not_header: true
				, not_footer: true
				, bg_contents: 'bg-gray01'
			}
			, item_cartel: {

			}
			,is_pin: false
			,pin_option: {
				pin_type: 'check'
			}
			,is_out: false
		}
	}
	, computed: {

	}
	, methods: {

		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data

					switch (this.item_cartel.cartl_concern_sphere_code){
						case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
						case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
						case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
						case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
					}

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,postCartelClosure: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_closure
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.$emit('to', { name: 'mafia044'})
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_pin = false
			}
		}
		, onPin: function(){
			this.$bus.$emit('onPin', 'check')

			this.$bus.$on('pinCallback', ()=> {
				this.postCartelClosure()
			})
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getCartel()
	}
}
</script>